import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./style/theme/index.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";

import '@/permission' // 路由权限控制

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
