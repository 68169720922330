import Cookie from "js-cookie";
const state = {
  isCollapse: false, //控制左侧菜单的展开
  menu: [], //存储菜单数据
};
const mutations = {
  // 修改菜单展开的方法
  COLLAPSE_MENU(state) {
    state.isCollapse = !state.isCollapse;
  },
  // 设置Menu的数据
  setMenu(state, menu) {
    state.menu = menu;
    Cookie.set("menu", JSON.stringify(menu));
  },
};

export default {
  state,
  mutations,
};
