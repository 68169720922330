import Main from "@/views/Main.vue";

const managementsRouter = {
  path: "/managements/",
  component: Main,
  name: "Managements",
  meta: {
    title: "管理系统",
    icon: "el-icon-document",
    roles: ["admin"],
  },
  children: [
    {
      path: "cluster",
      component: () => import("@/views/Managements/Cluster"),
      name: "Cluster",
      meta: { title: "集群管理" },
    },
    {
      path: "people",
      component: () => import("@/views/Managements/People"),
      name: "People",
      meta: { title: "人员管理" },
    },
  ],
};

export default managementsRouter;
