import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import tab from "./modules/tab";
import user from "./modules/user";
import permission from "./modules/permission";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    tab,
    user,
    permission,
  },
  getters,
});


export default store;