import Main from "@/views/Main.vue";

const calculationsRouter = {
  path: "/calculations/",
  component: Main,
  name: "Calculations",
  meta: {
    title: "仿真优化",
    icon: "el-icon-data-analysis",
    roles: ["admin", "user"],
  },
  children: [
    {
      path: "simulation",
      component: () => import("@/views/Calculations/Simulation"),
      name: "simulation",
      meta: { title: "发电仿真" },
    },
    {
      path: "layout-optimize",
      component: () => import("@/views/Calculations/LayoutOptimize"),
      name: "LayoutOptimize",
      meta: { title: "布局优化" },
    },
    {
      path: "control-optimize",
      component: () => import("@/views/Calculations/ControlOptimize"),
      name: "ControlOptimize",
      meta: { title: "优化控制" },
    },
    {
      path: "load",
      component: () => import("@/views/Calculations/Load"),
      name: "Load",
      meta: { title: "载荷分析" },
    },
    {
      path: "noise",
      component: () => import("@/views/Calculations/Noise"),
      name: "Noise",
      meta: { title: "噪音分析" },
    },
    {
      path: "economy",
      component: () => import("@/views/Calculations/Economy"),
      name: "Economy",
      meta: { title: "经济性分析" },
    },
  ],
};
export default calculationsRouter;
