<template>
  <el-col>
    <el-menu
      class="menu"
      :default-active="$route.name"
      :collapse="isCollapse"
      :collapse-transition="true">
      <el-tooltip content="展开" placement="right" :disabled="!isCollapse">
        <el-menu-item index="collapse" @click="changeCollapse">
          <div class="collapseWrap">
            <i :class="collapseIcon" />
          </div>
        </el-menu-item>
      </el-tooltip>
      <sidebar-item
        v-for="route in routes"
        :key="route.path"
        :item="route"
        :base-path="route.path" />
    </el-menu>
  </el-col>
</template>

<script>
import SidebarItem from "./SidebarItem";
export default {
  components: {
    SidebarItem,
  },
  data() {
    return {
      collapseIcon: "el-icon-s-fold",
    };
  },
  methods: {
    changeCollapse() {
      this.$store.commit("COLLAPSE_MENU");
      this.collapseIcon = this.isCollapse
        ? "el-icon-s-unfold"
        : "el-icon-s-fold";
    },
    clickMenu(item) {
      if (this.$route.path !== item.path) {
        this.$router.push(item.path);
      }
    },
  },
  computed: {
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    routes() {
      return this.$store.state.permission.routes;
    },
  },
};
</script>

<style lang="less" scoped>
.el-col {
  height: 100%;
  width: 100%;
  .el-menu:not(.el-menu--collapse) {
    width: 200px;
  }
  .collapseWrap {
    text-align: center;
  }
}
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.el-menu {
  height: 100%;
}
</style>
