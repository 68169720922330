<template>
  <div class="header-container">
    <logo class="flex-container" />
    <div class="flex-container">
      <datetime />
      <avatar-dropdown />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/CommonHeader/Logo";
import Datetime from "@/components/CommonHeader/Datetime";
import AvatarDropdown from "@/components/CommonHeader/AvatarDropdown";
export default {
  
  components: {
    Logo,
    Datetime,
    AvatarDropdown
  },
 
 

};
</script>

<style lang="less" scoped>
.header-container {
  background-color: #1d4999;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
}
</style>
