import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store/index";
import { getToken } from "@/utils/auth";
import { baseAPIExpressURL } from "/rootURL";

const http = axios.create({
  // 通用请求地址前缀
  baseURL: `${baseAPIExpressURL}`,
  timeout: 10000,
});

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前查看Vuex是否有token，有则添加到请求头中
    if (store.getters.token) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    console.log("interceptors.request error:", error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {

    const res = response.data;
    // 默认后端返回的res.data内的成功代码为20000，否则就是错误
    if (res.code !== 20000) {
      Message({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      // 50008: Illegal token; 50014: Token expired;
      if (res.code === 50008 || res.code === 50014) {
        // to re-login
        MessageBox.confirm(
          "您已被登出，你可以点击取消来留在本页，或者点击登录来再次登录",
          {
            confirmButtonText: "重新登陆",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });
      }
      // 收到200响应，但是res.data.codes是错误码
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  function (error) {
    console.log("来自响应拦截器的error", error);
    Message({
      message: error.response.data.message||error.message||"Error",
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default http;
