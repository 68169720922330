<template>
  <div class="nowtime">
    <h3 style="color: #fff">{{ date }}</h3>
  </div>
</template>

<script>
import dateFormatter from "@/utils/dateFormatter";
export default {
  data() {
    return {
      date: null,
    };
  },
  methods: {
    updateFormatDate() {
      this.timer = setInterval(() => {
        this.date = dateFormatter(new Date(), "Chinese");
      }, 1000);
    },
  },
  mounted() {
    this.date = dateFormatter(new Date(), "Chinese");
    this.updateFormatDate();
  },
    beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style lang="less" scoped>
.nowtime {
  margin-right: 15px;
}
</style>
