import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";

const whiteList = ["/","/login"]; // 不重定向的白名单

router.beforeEach(async (to, from, next) => {
  // 设置页面标题
  document.title = getPageTitle(to.meta.title);
  // 确定用户是否已登录
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      // 确定用户是否通过getInfo获得了他的权限角色
      const hasRoles = store.getters.role;
      if (hasRoles) {
        next();
      } else {
        try {
          // 获取用户信息
          const { role } = await store.dispatch("user/getInfo");
          // 根据身份获取路由权限
          const accessRoutes = await store.dispatch(
            "permission/generateRoutes",
            role
          );
          // 动态添加路由
          router.addRoutes(accessRoutes);
          next({ ...to, replace: true });
        } catch (error) {
          // 没有role信息
          // 删除token，进入登录页面重新登录
          await store.dispatch("user/resetToken");
          Message.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
  }
});
