//* 这个函数不仅内部是async+await模式的，在外部使用的时候也要用async+await模式

import { getInfo } from "@/api/user";
import dateFormatter from "@/utils/dateFormatter";
import store from "@/store";

const getUserInfoFromDB = async () => {
  const token = store.state.user.token;
  const res = await getInfo(token);
  if (res.code === 20000) {
    res.data.created_at = dateFormatter(
      new Date(res.data.created_at),
      "Chinese"
    );
    res.data.last_login = dateFormatter(
      new Date(res.data.last_login),
      "Chinese"
    );
    const user_info = res.data;
    return user_info;
  }
};
export default getUserInfoFromDB;
