//用户相关接口
import http from "@/utils/requestMyApi.js";

// 登陆验证接口
export const login = (data) => http.post("/user/login", data);

// 获取用户信息接口
export const getInfo = (token) =>
  http.get("/user/user-info", {
    params: {
      token: token,
    },
  });

// 获取用户列表接口
export const getUserList = (data) => http.get("/user/user-list", data);

// 创建用户接口
export const createUser = (data) => http.post("/user/create-user", data);

// 管理员编辑用户信息接口
export const editUser = (data) => http.put("/user/edit-user", data);

// 删除用户接口
export const deleteUser = (user_id) =>
  http.delete("/user/delete-user", {
    params: {
      user_id: user_id,
    },
  });

// 个人编辑用户信息接口
export const editInfo = (data) => http.put("/user/edit-info", data);