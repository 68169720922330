<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren)
      ">
      <el-menu-item
        :index="onlyOneChild.name"
        @click="clickMenu(onlyOneChild.path)">
        <i :class="onlyOneChild.meta.icon" />
        <span slot="title">
          {{ onlyOneChild.meta.title }}
        </span>
      </el-menu-item>
    </template>

    <el-submenu v-else :index="item.name">
      <template slot="title">
        <i :class="item.meta.icon" />
        <span>
          {{ item.meta.title }}
        </span>
      </template>
      <el-menu-item
        v-for="subItem in item.children"
        :key="subItem.name"
        :index="subItem.name"
        @click="clickMenu(subItem.path)">
        {{ subItem.meta.title }}
      </el-menu-item>
    </el-submenu>
  </div>
</template>

<script>
export default {
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    clickMenu(item_path) {
      const path = `${this.basePath}${item_path}`;
      if (this.$route.path !== path) {
        this.$router.push({
          path: path,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.el-menu--collapse {
  height: 100%;
}
.el-submenu {
  .el-menu-item {
    background-color: #f1f4f7 !important;
    // border-bottom: 1px solid #5e6779 !important;
    &:hover {
      background-color: #e8edf5 !important;
    }
  }
}
</style>
