import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import overviewRouter from "@/router/modules/overview";
import resourcesRouter from "@/router/modules/resources";
import calculationsRouter from "@/router/modules/calculations";
import managementsRouter from "@/router/modules/managements";
import userRouter from "@/router/modules/user";
import forcastsRouter from "@/router/modules/forcasts";

export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/Login"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "/",
    component: () => import("@/views/Intro"),
    name: "Intro",
    meta: { title: "平台介绍" },
    hidden: true,
  },
];

export const asyncRoutes = [
  overviewRouter,
  resourcesRouter,
  forcastsRouter,
  calculationsRouter,
  managementsRouter,
  userRouter,
  { path: "*", redirect: "/404", hidden: true },
];
const createRouter = () =>
  new VueRouter({
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
