import Main from "@/views/Main.vue";

const forcastsRouter = {
  path: "/forcasts/",
  component: Main,
  name: "Forcasts",
  meta: {
    title: "资源预测",
    icon: "el-icon-data-line",
    roles: ["admin", "user"],
  },
  children: [
    {
      path: "wind",
      component: () => import("@/views/Forcasts/WindForcast"),
      name: "WindForcast",
      meta: { title: "风速预报" },
    },
    {
      path: "power",
      component: () => import("@/views/Forcasts/PowerForcast"),
      name: "PowerForcast",
      meta: { title: "功率预报" },
    },
  ],
};
export default forcastsRouter;
