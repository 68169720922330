const rootURLs = {
  production: "1.94.132.181", // 线上 (生产环境)
  development: "localhost", // 本地 (开发环境)
};

const env = process.env.NODE_ENV || "development";
export const baseAPIExpressURL =
  env === "production"
    ? `http://${rootURLs.production}/api`
    : `http://${rootURLs.development}:3000/api`;

export const baseWSExpressURL =
  env === "production"
    ? `ws://${rootURLs.production}/ws`
    : `ws://${rootURLs.development}:3000/ws`;
export const baseAPIFlaskURL =
  env === "production"
    ? `http://${rootURLs.production}/flask`
    : `http://${rootURLs.development}:5000`;
export const baseWSFlaskURL =
  env === "production"
    ? `ws://${rootURLs.production}/flaskws`
    : `ws://${rootURLs.development}:5000`;
export const basePublicURL =
  env === "production"
    ? `http://${rootURLs.production}`
    : `http://${rootURLs.development}:3000/public`;