<template>
  <div id="l-content" @click="toIntro">
    <img class="long-logo" src="@/assets/images/longLOGO.png" />
    <h2 v-if="isShowTitle" style="color: #fff">SLOWA风电场全生命周期智慧应用服务平台</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toIntro() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
#l-content {
  .long-logo {
    height: 40px;
    margin-right: 10px;
    -webkit-user-drag: none;
  }
  cursor: pointer;
}
#l-content:hover {
  opacity: 0.8;
  transition: opacity 0.3s;
}
</style>
