import Main from "@/views/Main.vue";
const userRouter = {
  path: "/user/",
  redirect: "/user/user-info",
  component: Main,
  name: "User",
  hidden: true,
  meta: {
    roles: ["admin", "user", "guest"],
  },
  children: [
    {
      path: "user-info",
      component: () => import("@/views/UserInfo"),
      name: "user-info",
      meta: { title: "用户信息" },
    },
  ],
};
export default userRouter;
