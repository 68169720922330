<template>
  <el-dropdown @command="handleClick">
    <span class="el-dropdown-link">
      <el-avatar :size="35">{{nick_name}}</el-avatar>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="user-info">个人信息</el-dropdown-item>
      <el-dropdown-item command="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import getUserInfoFromDB from "@/utils/get-user-info";
export default {
  data() {
    return {
      nick_name: "",
    };
  },
  methods: {
    async handleClick(command) {
      if (command === "logout") {
        await this.$store.dispatch("user/logout");
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      }
      if (command === "user-info") {
        if (this.$route.path !== "/user/user-info") {
          this.$router.push("/user/user-info");
        }
      }
    },
  },
  mounted() {
    getUserInfoFromDB().then((res) => {
      this.nick_name = res.nick_name;
    });
  },
};
</script>

<style lang="less" scoped>
.user {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
</style>
