import Main from "@/views/Main.vue";
const overviewRouter = {
  path: "/overview/",
  redirect: "/overview/overview",
  component: Main,
  name: "Overview",
  meta: {
    roles: ["admin", "user", "guest"],
  },
  children: [
    {
      path: "overview",
      component: () => import("@/views/NewOverview"),
      name: "overview",
      meta: { title: "总览", icon: "el-icon-s-home" },
    },
  ],
};
export default overviewRouter;
