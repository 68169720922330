import Main from "@/views/Main.vue";

const resourcesRouter = {
  path: "/resources/",
  component: Main,
  name: "Resources",
  meta: {
    title: "资源信息",
    icon: "el-icon-wind-power",
    roles: ["admin", "user"],
  },
  children: [
    {
      path: "wind",
      component: () => import("@/views/Resources/Wind"),
      name: "wind",
      meta: { title: "风资源" },
    },
    {
      path: "terrain",
      component: () => import("@/views/Resources/Terrain"),
      name: "terrain",
      meta: { title: "高程数据" },
    },

    {
      path: "turbine",
      component: () => import("@/views/Resources/Turbine"),
      name: "Turbine",
      meta: { title: "风力机信息" },
    },
  ],
};
export default resourcesRouter;
